import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/business/wallet',
    component: Layout,
    redirect: '/business/wallet/wallet/index',
    meta: {
      title: '虚拟币管理',
      keepAlive: true,
      requiresAuth: true
    },
    children: [
      {
        path: 'wallet/index',
        name: 'BusinessWalletWalletIndex',
        component: () => import('@/views/business/wallet/wallet/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '伴医云检虚拟币管理'
        }
      },
      {
        path: 'bank/index',
        name: 'BusinessWalletBankIndex',
        component: () => import('@/views/business/wallet/bank/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '伴医云检银行卡管理'
        }
      },
      {
        path: 'cash/index',
        name: 'BusinessWalletCashIndex',
        component: () => import('@/views/business/wallet/cash/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '伴医云检提现管理'
        }
      },
      {
        path: 'user/index',
        name: 'BusinessWalletUserIndex',
        component: () => import('@/views/business/wallet/user/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '大家医生虚拟币'
        }
      }
    ]
  }
]

export default router
